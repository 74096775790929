<template>
  <div>
    <div class="d-flex flex-row justify-content-end" style="height: 40%; margin-top: 8%">
      <div class="pt-4">
        <w-white-dropdown :options="brandDropdown" :placeHolder="'Select'" :selectedOption="selectedBrand"
          :labelText="'Brand'" class="mr-3" @input="selectBrand($event)"></w-white-dropdown>
      </div>

      <div class="pt-4">
        <w-white-dropdown :options="mediaDropdown" :placeHolder="'Select'" :selectedOption="selectedMedia"
          :labelText="'KPI'" class="mr-3" @input="selectMedia($event)"></w-white-dropdown>
      </div>
    </div>
    <div>
      <div style="margin-left: 30px; margin-right: 30px" class="content-wrapper">
        <div class="fs-2 font-weight-bold">Scenario Planning</div>
        <h2 class="ProximaNovaBold d-flex justify-content-center mt-5">
          Step 1: Download the Format
        </h2>
        <div style="margin-top: 20px">
          <b-row class="">
            <b-col class="d-flex justify-content-center mt-5">
              <div class="dataCard">
                <div class="row">
                  <div class="col-md-8 col-8 pr-0">
                    <div class="d-flex justify-content-between">
                      <div class="Last-year-title py-2">Months</div>
                      <span class="line"></span>
                    </div>
                  </div>
                  <div class="col-md-4 col-4 pl-0 d-flex align-items-center">
                    <span class="Last-year-value"> {{ mediaData.duration }} </span>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col class="d-flex justify-content-center mt-5">
              <div class="dataCard">
                <div class="row">
                  <div class="col-md-8 col-8 pr-0">
                    <div class="d-flex justify-content-between">
                      <div class="Last-year-title py-2">Optimised Budget</div>
                      <span class="line"></span>
                    </div>
                  </div>
                  <div class="col-md-4 col-4 pl-0 d-flex align-items-center">
                    <span class="Last-year-value">
                      {{ this.formatValue(mediaData.predictions[1].value) }}
                    </span>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col class="d-flex justify-content-center mt-5">
              <div class="dataCard">
                <div class="row">
                  <div class="col-md-8 col-8 pr-0">
                    <div class="d-flex justify-content-between">
                      <div class="Last-year-title py-2">Expected Revenue</div>
                      <span class="line"></span>
                    </div>
                  </div>
                  <div class="col-md-4 col-4 pl-0 d-flex align-items-center">
                    <span class="Last-year-value">{{ this.formatValue(mediaData.predictions[3].value) }}
                    </span>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col class="d-flex justify-content-center mt-5">
              <div class="dataCard">
                <div class="row">
                  <div class="col-md-8 col-8 pr-0">
                    <div class="d-flex justify-content-between">
                      <div class="Last-year-title py-2">Expected ROI</div>
                      <span class="line"></span>
                    </div>
                  </div>
                  <div class="col-md-4 col-4 pl-0 d-flex align-items-center">
                    <span class="Last-year-value">{{ this.formatValue(mediaData.predictions[7].value) }} %</span>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col class="d-flex justify-content-center mt-5">
              <w-button style="width: 356px; height: 60px; margin-right: 50px; font-size: 20px" :buttonLoader="'normal'"
                :buttonText="'Download'" @buttonClicked="handleTemplateDownload">
              </w-button>
            </b-col>
          </b-row>

          <b-row>
            <b-col style="margin-top: 80px">
              <h2 style="font-size: 20px" class="ProximaNovaBold d-flex justify-content-center">
                Step 2: Upload the Data
              </h2>
              <div class="card-header font-large ProximaNovaBold content-center" style="
                  justify-content: space-between;
                  background: transparent;
                  border: 0px !important;
                ">
                <div class="mt-4">Enter name for the scenario</div>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-row class="mt-3">
          <b-col>
            <input v-model="scenarioName" class="input-box m-0" style="width: 256px; font-size: 16px"
              placeholder="Enter scenario name" @input="getScenarioName($event)" />
          </b-col>
          <b-col>
            <div style="width: 330px" class="px-0 pb-5 d-flex justify-center input-types">
              <input type="file" id="actual-btn" ref="fileInput" accept=".xlsx" @change="onFileChange" hidden />
              <label class="file-label" for="actual-btn" v-if="seen">Choose File</label>
              <span id="file-chosen" ref="file-chosen" class="d-flex"
                style="justify-content: space-between; font-size: 15px">
                {{ file }}
                <label v-if="!seen" class="remove-label mr-5" @click="clearFileUploader">Remove</label>
              </span>
            </div>
          </b-col>

          <b-col>
            <w-button :isDisable="!showOptimizeButton" style="width: 206px; margin-right: 50px" :buttonLoader="'normal'"
              :buttonText="'Optimize'" @buttonClicked="handleUploadTemplateDownload">
            </w-button>
          </b-col>
        </b-row>
      </div>
    </div>
    <w-page-loader v-if="isLoading" />
  </div>
</template>

<script>
import { AimoUnileverServices } from "@/services/AimoUnileverServices.js";
const aimoUnileverService = new AimoUnileverServices();
import Button from "@/components/Profile/Button.vue";
import WhiteDropdown from "@/widgets/WhiteDropdown.vue";
import { abbreviateNumber } from "js-abbreviation-number";
import PageLoader from "@/widgets/PageLoader.vue";

export default {
  components: {
    "w-button": Button,
    "w-white-dropdown": WhiteDropdown,
    "w-page-loader": PageLoader,
  },
  data() {
    return {
      mediaData: JSON.parse(localStorage.getItem("optimizeData")),
      scenarioName: "",
      seen: true,
      isLoading: false,
      showOptimizeButton: false,
      file: "Upload file",
      uplodaData: "",
      isViewDetails: false,
      brandName: "",
      brandDropdown: [
        {
          text: localStorage.getItem("selectedBrandKPI"),
          id: localStorage.getItem("selectedBrandKPI"),
        },
      ],
      mediaDropdown: [
        // { text: "Overall", id: "Overall" },
        // { text: "Overall", id: "overall" },
      ],
      selectedMedia: {
        text: "Overall",
        id: "Overall",
      },
      selectedBrand: {
        // text: localStorage.getItem("selectedBrandKPI"),
        // id: localStorage.getItem("selectedBrandKPI").toLowerCase(),
      },
      selectedDurationInBBO: +sessionStorage.getItem("selectedDurationInBBO"),
      selectedRunIdBBO: sessionStorage.getItem("selectedRunIdBBO"),
    };
  },
  methods: {
    formatValue(data) {
      return abbreviateNumber(data, 2, {
        symbols: ["", "k", "M", "B", "T", "P", "E"],
      });
    },
    saveToLocal() {
      // if (this.selectedMedia.id && this.selectedBrand.id !== null) {
      localStorage.setItem(
        "selectedKPISource",
        `${this.selectedBrand.id}_${this.selectedMedia.id}`
      );
      this.marketType = `${this.selectedBrand.id}_${this.selectedMedia.id}`;
      localStorage.setItem("selectedBrandKPI", this.selectedBrand.text);
      localStorage.setItem("selectedMediaKPI", this.selectedMedia.text);

      // localStorage.setItem("selectedBrandKPI", this.selectedKPISource.text)
      // }
    },
    selectBrand(e) {
      this.selectedBrand = e;
      this.saveToLocal();
      this.brandName = e.text;
    },
    selectMedia(e) {
      this.selectedMedia = e;
      // this.addAllTimeData();
      // this.addsalseStatics();
      this.saveToLocal();
      // this.callApis();
    },
    clearFileUploader(e) {
      this.$refs.fileInput.value = null;

      this.file = "No File chosen";
      this.seen = true;
      this.uplodaData = "";
      e.preventDefault();
    },
    onFileChange(event) {
      // this.scenarioDetails[index].fileChosen.seen = false;
      // this.scenarioDetails[index].fileChosen.fileData = event.target.files[0];
      // this.scenarioDetails[index].fileChosen.uploadData = event.target.files[0];
      // this.scenarioDetails[index].fileChosen.file = this.scenarioDetails[index].fileChosen.fileData

      this.seen = false;
      var fileData = event.target.files[0];
      this.uplodaData = event.target.files[0];

      this.file = fileData.name;
      this.showOptimizeButton = true;
    },
    getScenarioName(e) {
      this.scenarioName = e.target.value;
      // if (this.scenarioDetails[index] != e) {
      //   this.scenarioDetails[index].scenarioName = e.target.value;
      // }
    },
    handleTemplateDownload() {
      //   let weeks = this.numOfWeeks;
      //   let startDate = this.formatDate(this.selectedForecastingDate);
      //   let duration = this.selectedDurationInBBO;
      //   let runId = this.selectedRunIdBBO;

      let optimizeData = JSON.parse(localStorage.getItem("optimizeData"));
      let brand = optimizeData.brand;
      let runId = optimizeData.runId;
      let duration = optimizeData.duration;

      aimoUnileverService.getBBOScenarioPlanning(brand, duration, runId).then(() => { });
    },
    handleUploadTemplateDownload() {
      let brand = this.selectedBrand.text;
      let optimizeData = JSON.parse(localStorage.getItem("optimizeData"));
      let runId = optimizeData.runId;
      let clientId = sessionStorage.getItem("subId")
      let duration = optimizeData.duration;

      let data = new FormData();
      data.append("file", this.uplodaData);
      this.isLoading = true;
      aimoUnileverService
        .optimizeBBOScenarioPlanning(brand, duration, runId, data, clientId)
        .then((res) => {
          sessionStorage.setItem("currentTab", "bbo");

          localStorage.setItem("optimizeData", JSON.stringify(res));
          sessionStorage.setItem("fronScenarioPlanning", true);
          this.isLoading = false;
          this.$router.push("/solutions/ul/scenario-planning/planning-results");
        })
        .catch((err) => {
          this.isLoading = false;
          alert(err);
        });
    },
  },
  created() {
    let optimizeData = JSON.parse(localStorage.getItem("optimizeData"));
    this.selectedBrand = { text: optimizeData.brand, id: optimizeData.brand };
    // this.brandDropdown = { text: optimizeData.brand, id: optimizeData.brand };
  },
};
</script>

<style scoped>
.form-check-input:focus {
  box-shadow: none !important;
  outline: none !important;
}

.form-check-input {
  height: 24px;
  /* margin: 68px 11px 18px 0;
  margin-top: 90px; */
  object-fit: contain;
  position: relative;
  top: 0px;
  margin: 0px;
  margin-right: 10px;
}

.form-check-input:checked {
  background-color: rgb(104, 222, 104);
  border-color: #14181e;
}

.Remember-me {
  width: 40%;
  height: 20px;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222a37;
  cursor: pointer;
  margin: 0px;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

@import url("https://fonts.googleapis.com/css2?family=Sora&display=swap");

.chart-row {
  margin-top: 100px;
}

.chart-row-short {
  margin-top: 70px;
}

.dataCard {
  max-width: 90%;
  width: 100%;
  min-height: 55px;
  margin-bottom: 100px;
  padding: 13px 9px 13px 15px;
  border-radius: 3px;
  box-shadow: 0 0 8px 0 #c9ced5;
  background-color: #ffffff;
}

.dataCard a {
  float: left;
  font-size: 16px;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.middleDatacard {
  margin-bottom: 31px;
}

.slider-wrapper {
  display: flex;
  flex-direction: row;
  height: 240px;
}

.Last-year-title {
  font-family: ProximaNovaRegular;
  color: #727988;
  font-size: 16px;
}

.line {
  width: 1px;
  margin: 0 11px;
  border: solid 1px #c6ccd6;
}

.Last-year-value {
  font-family: ProximaNovaSemiBold;
  font-size: 16px;
  font-weight: 600;
  color: #050505;
}

@media screen and (max-width: 768px) {
  .chart-row {
    margin-top: 30px;
  }

  .dataCard {
    margin-bottom: 30px;
  }
}
</style>
<style>
.rs-handle.rs-move {
  border: 11px solid #fff;
}

.rs-handle {
  background-color: var(--handleColor) !important;
}

.rs-handle.rs-focus {
  border: 11px solid #fff;
}

.ProductNew {
  font-family: ProximaNovaBold;
  font-size: 15px;
  color: #222a37;
}

.input-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  /* Not Equal*/
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
}

.input-box {
  font-family: ProximaNovaBold;
  color: #000;
  height: 60px;
  width: 120px;
  font-size: 12px;
  padding: 10px;
  margin: 10px;
  background-color: rgb(239, 245, 255);
  box-sizing: border-box;
  border: 1px solid #ccc;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}

.sliderIcon {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  background-color: #f3f3f3;
}

.sliderIcon i {
  font-size: 20px;
}

:-ms-input-placeholder {
  color: #9aafd4;
}

::-ms-input-placeholder {
  color: #9aafd4;
}

.changeColor .disableButton {
  color: #fff !important;
}

.action {
  color: #000;
}

.container {
  height: 100%;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
  margin-bottom: 8px;
}

.step-indicator .stepactive::after {
  background: #050505;
}

.tab-panel.showConnectors {
  display: block;
}

.tab-panel {
  display: none;
}

.card {
  display: block;
}

.Use-product-data-to {
  height: 35px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #c9ced5;
}

.form-file-input {
  margin: 0px;
  padding: 15px;
  width: 100%;
  outline: none;
  height: 60px;
  border-radius: 5px;
  font-size: 20px;
  color: #9aafd4;
  border: 0;
  background-color: #eff5ff;
}

.choose-file-btn {
  width: 115px;
  position: absolute;
  top: 0;
  border-radius: 5px;
  right: 0px;
  z-index: 2;
  border: none;
  top: 7px;
  height: 44px;
  cursor: pointer;
  color: #222a37;
  background-color: #eff5ff;
  transform: translateX(-39px);
  border: 2px solid #050505;
  font-size: 16px;
}

label.file-label {
  width: 115px;
  position: absolute;
  top: 0;
  border-radius: 5px;
  right: 0px;
  border: none;
  top: 7px;
  height: 44px;
  cursor: pointer;
  color: #222a37;
  background-color: #eff5ff;
  transform: translateX(-39px);
  border: 2px solid #050505;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ProximaNovaRegular;
}

.remove-label {
  cursor: pointer;
  color: #ff5a5a;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ProximaNovaRegular;
}

#file-chosen {
  margin: 0px;
  width: 100%;
  outline: none;
  height: 60px;
  border-radius: 5px;
  font-size: 15px;
  color: #9aafd4;
  border: 0;
  background-color: #eff5ff;
  margin-left: 0.3rem;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.input-types {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

span#file-chosen {
  color: #222a37;
  font-family: ProximaNovaBold;
  font-size: 16px;
}

span.d-flex.ProximaNovaSemiBold.p-3 {
  font-size: 18px !important;
  font-family: ProximaNovaRegular !important;
  font-weight: normal;
}

@media screen and (max-width: 640px) {
  .row.product-data-section {
    flex-direction: column;
    padding-bottom: 15px;
  }

  .input-types {
    position: relative;
    width: 75%;
    padding-right: 15px;
    padding-left: 15px;
  }

  .container-fluid {
    padding-left: 0 !important;
  }

  .col-md-12.col-lg-12.product-data-section {
    width: 100%;
    margin-left: 18%;
  }
}

@media screen and (max-width: 768px) {
  .row.product-data-section {
    flex-direction: column;
    padding-bottom: 15px;
  }

  img.img.img-responsive {
    margin: auto;
  }

  .input-types {
    position: relative;
    width: 75%;
    padding-right: 15px;
    padding-left: 15px;
  }

  .col-md-12.col-lg-12.product-data-section {
    width: 100%;
    margin: 0% !important;
  }
}

@media screen and (max-width: 900px) {
  .input-types {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }

  .col-md-12.col-lg-12.product-data-section {
    width: 100%;
    margin: 0% !important;
  }
}

@media screen and (max-width: 1024px) {
  #file-chosen {
    font-size: 15px !important;
  }

  .col-md-12.col-lg-12.product-data-section {
    width: 100%;
    margin: 0% !important;
  }
}

.download-channel-data {
  width: 100%;
  height: 20px;
  right: 0px;
  font-family: ProximaNovaRegular;
  font-size: 18px;
  font-weight: normal;
  position: relative;
  margin-top: 0px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222a37;
  text-decoration: underline;
  /* color: blueviolet; */
}

.download-channel-data>a {
  cursor: pointer;
}

.content-wrapper {
  margin-top: 24px;
  padding: 28px 23px 27px 24px;
  border-radius: 3px;
  box-shadow: 0 0 8px 0 #c9ced5;
  background-color: #ffffff;
}
</style>
